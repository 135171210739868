import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class Customer extends Model {
	protected jsonApiType = 'accounting/customers'
	protected static pageSize = 10

	@Property()
	public name!: string

	@Property()
	public email!: string

	@Property()
	public reference!: string | null
}
