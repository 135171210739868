
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AdminContactRequest } from '@/models/AdminContactRequest'
import User from '@/models/User'
import { namespace } from 'vuex-class'
import Notification from '@/classes/Notification'
import i18n from '@/i18n'

const Auth = namespace('auth')

@Component
export default class ContactDialog extends Vue {
	@Prop({
		type: Boolean,
		required: true,
		default: false
	})
	opened!: boolean

	loading: boolean = false

	@Auth.State('user')
	user!: any

	comment: string = ''

	async onSave() {
		const valid = await this.$validator.validateAll()
		if (!valid) {
			return
		}

		this.loading = true

		const user = new User()
		user.setApiId(this.user.id)

		const adminContactRequest = new AdminContactRequest()
		adminContactRequest.comment = this.comment
		adminContactRequest.activeUrl = this.$router.currentRoute.fullPath
		adminContactRequest.setUser(user)

		try {
			await adminContactRequest.save()

			await Notification.Success(i18n.t('contact.success').toString())

			this.$emit('closing')
		} finally {
			this.loading = false
		}
	}
}
