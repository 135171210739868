import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import User from '@/models/User'

export class AdminContactRequest extends Model {
	protected jsonApiType: string = 'adminContactRequests'

	@Property()
	public comment!: string

	@Property()
	public activeUrl!: string

	private users(): ToOneRelation {
		return this.hasOne(User, 'users')
	}

	public getUser(): User {
		return this.getRelation('users')
	}

	public setUser(user: User): void {
		return this.setRelation('users', user)
	}
}
