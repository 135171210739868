import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import Property from '@/decorators/Property'
import { Terminal } from '@/models/Terminal'
import { GeographicAreas } from '@/models/GeographicAreas'
import { TerminalSrsLocation } from '@/models/TerminalSrsLocation'
import { MappableModel } from '@/interfaces/MappableModel'
import User from '@/models/User'
import { Vessel } from '@/models/Vessel'

export class EmptyVessel extends Model implements MappableModel {
	protected jsonApiType = 'empties'

	@Property()
	public geometry!: string

	@Property()
	public emptyAt!: string

	@Property()
	public place!: string | null

	@Property()
	public province!: string | null

	@Property()
	public countryCode!: string

	@Property()
	public notifyShippers!: boolean

	@Property()
	public isCustomLocation!: boolean

	@Property()
	public isPublic!: boolean

	@Property()
	public expiresAt!: string

	@Property()
	public canDelete: boolean = false

	@Property()
	public remark!: string | null

	public user(): ToOneRelation {
		return this.hasOne(User, 'user')
	}

	public getUser(): User {
		return this.getRelation('user')
	}

	public geographicAreas(): ToManyRelation {
		return this.hasMany(GeographicAreas, 'geographicAreas')
	}

	public getGeographicAreas(): Array<GeographicAreas> {
		return this.getRelation('geographicAreas') ?? []
	}

	public terminal(): ToOneRelation {
		return this.hasOne(Terminal, 'terminal')
	}

	public setTerminal(terminal: Terminal): void {
		this.setRelation('terminal', terminal)
	}

	public getTerminal(): Terminal {
		return this.getRelation('terminal')
	}

	public getLocation(): GeoJSON.Geometry {
		return this.getAttribute('customGeoLocation')
	}

	public vessel(): ToOneRelation {
		return this.hasOne(Vessel, 'vessel')
	}

	public getVessel(): Vessel {
		return this.getRelation('vessel')
	}

	public setVessel(vessel: Vessel): void {
		this.setRelation('vessel', vessel)
	}

	public getName(): string {
		if (this.getUser()) {
			return this.getUser().fullName
		}

		if (this.getTerminal()) {
			return this.getTerminal().getName()
		}

		return ''
	}

	public getTooltipContent(): string {
		return this.getName()
	}

	public get pin(): string {
		return '/img/icons/markers/empty-vessel.svg'
	}

	public isSaving: boolean = false
}
