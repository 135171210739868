import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { CertificationClaimType } from '@/models/CertificationClaimType'
import DeliveryMethod from '@/enums/DeliveryMethod'

export class DeliveryTerms extends Model {
	protected jsonApiType = 'deliveryTerms'

	@Property()
	public certificationIsCE!: boolean | null

	@Property()
	public certificationCEMarking!: string | null

	@Property()
	public certificationIsBenor!: boolean | null

	@Property()
	public certificationIsBSB!: boolean | null

	@Property()
	public certificationBenorMarking: string | null = null

	@Property()
	public certificationBSBMarking: string | null = null

	@Property()
	public certificationBenorRevision!: string | null

	@Property()
	public certificationIsBRL!: boolean | null

	@Property()
	public certificationBRLMarking!: string | null

	@Property('desalinated')
	public isDesalinated!: boolean | null

	@Property()
	public dryPumpUsed!: boolean | null

	@Property()
	public certificationIsDOP!: boolean | null

	@Property()
	public certificationDOPMarking!: string | null

	@Property()
	public deliveryMethod!: DeliveryMethod | null

	public certificationClaims(): ToManyRelation {
		return this.hasMany(CertificationClaimType, 'certificationClaims')
	}

	public getCertificationClaims(): Array<CertificationClaimType> {
		return this.getRelation('certificationClaims') ?? []
	}

	public setCertificationClaims(claims: Array<CertificationClaimType>) {
		this.setRelation('certificationClaims', claims)
	}

	private applicabilityClaim(): ToOneRelation {
		return this.hasOne(CertificationClaimType, 'applicabilityClaim')
	}

	public getApplicabilityClaim(): CertificationClaimType | null {
		return this.getRelation('applicabilityClaim') ?? null
	}

	public setApplicabilityClaim(claim: CertificationClaimType | null) {
		this.setRelation('applicabilityClaim', claim)
	}
}
