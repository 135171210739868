import moment, { Moment } from 'moment'

import DocumentStatus from '@/enums/DocumentStatus'
import { Downloadable } from '@/interfaces/Downloadable'
import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Transport } from '@/models/Transport'
import User from '@/models/User'
import axios from 'axios'
import FileTag from '@/enums/FileTag'

export class Document extends Model implements Downloadable {
	protected jsonApiType: string = 'documents'

	@Property()
	public tag!: FileTag

	@Property()
	public module!: string

	@Property()
	public name!: string

	@Property()
	public fileName!: string

	@Property()
	public filePath!: string

	@Property()
	public createdAt!: string

	@Property()
	public sharedWithSubContractor!: boolean

	@Property()
	public canBeAttached!: boolean

	@Property()
	public expirationDate!: string

	@Property()
	public status!: DocumentStatus | null

	public uploader(): ToOneRelation {
		return this.hasOne(User, 'uploader')
	}

	public getUploader(): User | null {
		return this.getRelation('uploader') || null
	}

	private transport(): ToOneRelation {
		return this.hasOne(Transport, 'transport')
	}

	public getTransport(): Transport {
		return this.getRelation('transport')
	}

	public get parsedCreatedAt(): Moment {
		return moment(this.createdAt)
	}

	public get parsedExpirationDate(): Moment {
		return moment(this.expirationDate)
	}

	get fullFileName() {
		const items = this.fileName.split('/')
		return items[items.length - 1]
	}

	private static _maxRetries = 3

	public async triggerDownload(tryNumber: number = 1) {
		// Check if the current tryNumber exceeds the maximum retries per run
		if (tryNumber > Document._maxRetries) {
			throw new Error('Maximum number of retries exceeded.')
		}

		try {
			const receivedUrl = await this.getSignedUrl()

			if (!receivedUrl) return

			// call the received url to download the document
			const response = await axios.get(receivedUrl, {
				transformRequest: [
					(data, headers) => {
						delete headers.Authorization
						return data
					}
				],
				responseType: 'blob'
			})

			this.file = response.data as Blob
		} catch (err: any) {
			// retry to get another url when it expired
			if (err.response?.status === 403) {
				await this.triggerDownload(tryNumber++)
			} else {
				throw err
			}
		}
	}

	// call download endpoint to get the url
	private async getSignedUrl() {
		const response = await axios.get(
			`${this.getJsonApiBaseUrl()}${this.getJsonApiType()}/${this.getApiId()}/download`
		)
		return response.data['signed-uri']
	}

	get isDownloading() {
		return this.fileIsLoading
	}

	get downloadName() {
		return this.name
	}

	get downloadedFile() {
		return this.file
	}

	setIsDownloading(value: boolean) {
		this.fileIsLoading = value
	}

	public isLoading: boolean = false
	public file: Blob | null = null
	public fileIsLoading: boolean = false
}
