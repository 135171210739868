import { MapState } from '@/interfaces/MapState'
import { Facility } from '@/models/Facility'
import { RoutePlanner } from '@/models/RoutePlanner'
import { Vessel } from '@/models/Vessel'
import { MappableModel } from '@/interfaces/MappableModel'
import { LatLngBounds } from 'leaflet'

export const setFacilities = (state: MapState, features: Array<Facility>): void => {
	state.facilities = features
}

export const setVessels = (state: MapState, vessels: Array<Vessel>): void => {
	state.vessels = vessels
}

export const setActiveFacility = (state: MapState, facility: MappableModel): void => {
	state.activeFacility = facility
}

export const setQuery = (state: MapState, query: string | null): void => {
	state.query = query
}

export const setBoundingBox = (state: MapState, boundingBox: LatLngBounds | null): void => {
	state.boundingBox = boundingBox
}

export const setRoutes = (state: MapState, routes: Array<RoutePlanner>): void => {
	state.routes = routes
}

export const setRoutesLoading = (state: MapState, isLoading: boolean): void => {
	state.routesLoading = isLoading
}

export const updateFacility = (state: MapState, facility: Facility): void => {
	const facilityIndex = state.facilities.findIndex((f) => f.getApiId() === facility?.getApiId())
	state.facilities.splice(facilityIndex, 1, facility)
}

export const removeFacility = (state: MapState, facility: Facility): void => {
	const facilityIndex = state.facilities.findIndex((f) => f.getApiId() === facility?.getApiId())
	state.facilities.splice(facilityIndex, 1)
}
