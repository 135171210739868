import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { AttributeOption } from '@/models/AttributeOption'
import AttributeType from '@/enums/AttributeType'

export class Attribute extends Model {
	protected jsonApiType = 'attributeTypes'

	public get unit() {
		const title = '' + this.title
		const first = title.lastIndexOf('(')
		const second = title.lastIndexOf(')')
		if (first > 0 && first < second) {
			return title.substring(first + 1, second)
		}
		return null
	}

	public get titleWithoutUnit() {
		if (this.unit) {
			return this.title.replace(`(${this.unit})`, '')
		}
		return this.title
	}

	@Property()
	public slug!: string

	@Property()
	public title!: string

	@Property()
	public attributeType!: AttributeType

	@Property()
	public dataType!: string

	@Property()
	public userType!: string

	@Property()
	public subCategory!: string

	@Property()
	public sequence!: number

	@Property()
	public group!: string | null

	private attributeOptions(): ToManyRelation {
		return this.hasMany(AttributeOption, 'attributeOptions')
	}

	public getAttributeOptions(): Array<AttributeOption> {
		return this.getRelation('attributeOptions')
	}

	public static async fetchAllLazy(): Promise<Array<Attribute>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['attributes/hasAllFetched']()) {
			await store.dispatch('attributes/findAll')
		}

		return store.getters['attributes/peekAll']()
	}

	public static async fetchLazy(id): Promise<Attribute> {
		const store = (await import('@/store/index')).store
		if (!store.getters['attributes/hasCached'](id)) {
			await store.dispatch('attributes/findRecord', id)
		}

		return store.getters['attributes/peekRecord'](id)
	}
}
