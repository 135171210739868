import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Attribute } from '@/models/Attribute'
import AttributeType from '@/enums/AttributeType'

export class VesselAttribute extends Model {
	protected jsonApiType = 'vesselAttributes'

	@Property()
	public attributeType!: AttributeType

	@Property()
	public slug!: string

	@Property()
	public title!: string

	@Property()
	public value?: any

	@Property()
	public sequence!: number

	public originalAttributeType(): ToOneRelation {
		return this.hasOne(Attribute, 'originalAttributeType')
	}

	public getAttributeType(): Attribute | null {
		return this.getRelation('originalAttributeType') || null
	}

	public changedOrNew: boolean = false
}
