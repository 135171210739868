import { Model } from '@/models/Model'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { VesselAttribute } from '@/models/VesselAttribute'
import { GasOilUsage } from '@/models/GasOilUsage'
import { MappableModel } from '@/interfaces/MappableModel'
import Property from '@/decorators/Property'
import { Insights as InsightsModel } from '@/models/Insights'
import { VesselLog } from '@/models/VesselLog'
import { AisPosition } from '@/models/AisPosition'
import ListItem from '@/interfaces/ListItem'
import i18n from '@/i18n/index'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Company } from '@/models/Company'
import { PhoneCountryCode } from '@/models/PhoneCountryCode'
import { EmptyVessel } from '@/models/EmptyVessel'
import { runTasks } from '@/utils/task'
import { Document } from '@/models/Document'

export class Vessel extends Model implements MappableModel, ListItem {
	protected jsonApiType = 'vessels'
	protected static pageSize = 25

	public get title(): string {
		let extraText = ''
		if (this.ownerLastName) {
			extraText = `(${this.ownerLastName})`
		}
		return `${this.name} ${extraText}`
	}

	public get sub(): string {
		return i18n
			.t('vessel.listItem.sub', {
				eni: this.euNumber,
				length: this.length,
				width: this.width
			})
			.toString()
	}

	public get dimensions(): string {
		const width = this.width
		const length = this.length
		if (!width && length) {
			return length.toString()
		}

		return length + ' x ' + width
	}

	@Property()
	public email: string | null | undefined

	@Property()
	public name!: string | null | undefined

	@Property()
	public ownerLastName!: string | undefined

	@Property()
	public length!: number | null | undefined

	@Property()
	public width!: number | null | undefined

	@Property()
	public draught!: number | null | undefined

	@Property()
	public mmsi!: string | null | undefined

	@Property()
	public euNumber!: number | null | undefined

	@Property()
	public tonnage!: number | null | undefined

	@Property()
	public capacityCubicMeters!: number | null | undefined

	@Property()
	public capacityCubicMetersUncovered!: number | null | undefined

	@Property()
	public insurance!: string | null | undefined

	@Property()
	public insights?: Array<InsightsModel>

	@Property()
	public skipperName!: string | null | undefined

	@Property()
	public isRegistered!: boolean

	@Property()
	public isOwnFleet!: boolean

	@Property()
	public emptyWeight!: number | null | undefined

	@Property()
	public phoneNumber: string | undefined

	public phoneCountryCode(): ToOneRelation {
		return this.hasOne(PhoneCountryCode, 'phoneCountryCode')
	}

	public getPhoneCountryCode(): PhoneCountryCode | null {
		return this.getRelation('phoneCountryCode') || null
	}

	public setPhoneCountryCode(phoneCountryCode: PhoneCountryCode | null): void {
		this.setRelation('phoneCountryCode', phoneCountryCode)
	}

	public company(): ToOneRelation {
		return this.hasOne(Company, 'company')
	}

	public getCompany(): Company | null {
		return this.relation('company') || null
	}

	public setCompany(company: Company): void {
		return this.setRelation('company', company)
	}

	public getLocation(): GeoJSON.Geometry {
		return this.getAttribute('geoLocation')
	}

	public getName(): string {
		return this.getAttribute('name')
	}

	private aisPositions(): ToManyRelation {
		return this.hasMany(AisPosition, 'aisPositions')
	}

	public getAisPositions(): Array<AisPosition> {
		return this.relation('aisPositions') || []
	}

	private empty(): ToOneRelation {
		return this.hasOne(EmptyVessel, 'empty')
	}

	public getEmpty(): EmptyVessel | null {
		return this.relation('empty') || null
	}

	private vesselAttributes(): ToManyRelation {
		return this.hasMany(VesselAttribute, 'vesselAttributes')
	}

	public getVesselAttributes(): Array<VesselAttribute> {
		return this.getRelation('vesselAttributes') || []
	}

	public gasOilUsages(): ToManyRelation {
		return this.hasMany(GasOilUsage, 'gasOilUsages')
	}

	public getGasOilUsages(): Array<GasOilUsage> {
		return this.relation('gasOilUsages') || []
	}

	public get mmsiNumber(): string | null | undefined {
		return this.mmsi
	}

	public set mmsiNumber(value: string | null | undefined) {
		this.mmsi = value
	}

	public set aisPositionAccepted(value: boolean) {
		this.setVesselAttribute('ais_accept', value)
	}

	public get aisPositionAccepted(): boolean {
		const attribute = this.getVesselAttribute('ais_accept')

		return attribute ? attribute.value : false
	}

	public get canBeContacted(): boolean {
		return !(this.isOwnFleet && this.getEmpty())
	}

	public getVesselAttributesBySlug(slug: string): Array<VesselAttribute> {
		return this.getVesselAttributes().filter((attribute) => {
			return attribute.slug === slug
		})
	}

	public getVesselAttribute(slug: string): VesselAttribute | null {
		if (!this.getVesselAttributes()) {
			return null
		}

		return this.getVesselAttributesBySlug(slug)[0] ?? null
	}

	public setVesselAttribute(slug: string, value: any): void {
		let attribute = this.getVesselAttribute(slug)
		if (!attribute) {
			attribute = new VesselAttribute()
			attribute.slug = slug
			attribute.setRelation('vessel', this)

			const vesselAttributes = this.getVesselAttributes() || []
			vesselAttributes.push(attribute)
			this.setRelation('vesselAttributes', vesselAttributes)
		}
		attribute.changedOrNew = true
		attribute.value = value
	}

	public getNewOrChangedVesselAttributes(): Array<VesselAttribute> {
		return this.getVesselAttributes().filter((attribute) => attribute.changedOrNew)
	}

	public async saveAttributes(): Promise<void> {
		const tasks = this.getNewOrChangedVesselAttributes().map((attribute, i) => {
			return async () => {
				if (attribute.getApiId() && [undefined, null, ''].includes(attribute.value)) {
					await attribute.delete()
				} else {
					await attribute.save()
				}
				return i
			}
		})
		for await (const value of runTasks(5, tasks.values())) {
			// do nothing, but wait on all to be finished
		}
	}

	public vesselLogs(): ToManyRelation {
		return this.hasMany(VesselLog, 'vesselLogs')
	}

	public getVesselLogs(): Array<VesselLog> {
		return this.relation('vesselLogs') || []
	}

	public documents(): ToManyRelation {
		return this.hasMany(Document, 'documents')
	}

	public getDocuments(): Array<Document> {
		return this.getRelation('documents') || []
	}

	public setDocuments(documents: Array<Document>): void {
		return this.setRelation('documents', documents)
	}

	public static async fetchLazy(id): Promise<Vessel> {
		const store = (await import('@/store/index')).store
		if (!store.getters['vessels/hasCached'](id)) {
			await store.dispatch('vessels/findRecord', id)
		}

		return store.getters['vessels/peekRecord'](id)
	}
}
