import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class AttributeOption extends Model {
	protected jsonApiType = 'attributeOptions'

	@Property()
	public title!: string

	@Property()
	public sequence!: number
}
